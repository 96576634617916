import { Container } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import EmailIcon from "@material-ui/icons/EmailOutlined";
import LocationIcon from "@material-ui/icons/LocationOnOutlined";
import PhoneIcon from "@material-ui/icons/PhoneOutlined";
import React from "react";
import infoLogo from "../../images/infoLogo.png";
import {
	Col,
	CompanyLinksSection,
	CompanyLogoSection,
	Contact,
	ContactRow,
	Img,
	ImgWrapper,
	Info,
	SectionTitle,
	Text,
	TextWrapper,
	Title
} from "./elements";

const CompanySection = () => {
	return (
		<>
			<Info>
				<Container>
					<Col>
						<CompanyLogoSection>
							<ImgWrapper>
								<Img src={infoLogo} alt='infoLogo' />
							</ImgWrapper>
							<TextWrapper>
								<Title>
									Jumpp weckt Potenzial und schafft
									wirtschaftliche Perspektiven - und das seit
									mehr als 35 Jahren. Seit 1984 haben wir rund
									15.000 Frauen bei ihrem Einstieg in die
									Selbstständigkeit unterstützt. Gestern wie
									heute stärken wir die Wirtschaftskraft von
									Frauenunternehmen. Mehr dazu erfahren Sie in
									unseren Meilensteinen.
								</Title>
							</TextWrapper>
						</CompanyLogoSection>
						<CompanyLinksSection>
							<SectionTitle>Contact Details</SectionTitle>
							<Contact>
								<ContactRow>
									<LocationIcon
										fontSize='large'
										style={{ color: blue[300] }}
									/>
									<Text>
										Hamburger Allee 96 60486 Frankfurt am
										Main
									</Text>
								</ContactRow>
								<ContactRow>
									<PhoneIcon
										fontSize='large'
										style={{ color: blue[300] }}
									/>
									<Text>
										Tel .:{" "}
										<a href='tel:+06971589550'>
											(069) 715 89 55-0
										</a>
									</Text>
								</ContactRow>
								<ContactRow>
									<EmailIcon
										fontSize='large'
										style={{ color: blue[300] }}
									/>
									<Text>
										Email:{" "}
										<a href='mailto:info@jumpp.de'>
											info@jumpp.de
										</a>
									</Text>
								</ContactRow>
							</Contact>
						</CompanyLinksSection>
					</Col>
				</Container>
			</Info>
		</>
	);
};

export default CompanySection;
