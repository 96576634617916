import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import infoLogo from "../../images/infoLogo.png";

const useStyles = makeStyles({
	root: {
		marginTop: 10,
	},
	media: {
		height: 200,
		backgroundSize: "contain",
	},
});

export default function MediaCard() {
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardMedia
				className={classes.media}
				image={infoLogo}
				title='Jumpp'
			/>
			<CardContent>
				<Typography gutterBottom variant='h3' component='h1'>
					Jumpp
				</Typography>
				<Typography variant='h5' color='textSecondary' component='h4'>
					Jumpp weckt Potenzial und schafft wirtschaftliche
					Perspektiven - und das seit mehr als 35 Jahren. Seit 1984
					haben wir rund 15.000 Frauen bei ihrem Einstieg in die
					Selbstständigkeit unterstützt. Gestern wie heute stärken wir
					die Wirtschaftskraft von Frauenunternehmen. Mehr dazu
					erfahren Sie in unseren Meilensteinen.
				</Typography>
			</CardContent>
		</Card>
	);
}
