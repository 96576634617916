import { useMediaQuery } from "@material-ui/core";
import * as React from "react";
import {
	BulkDeleteWithConfirmButton,
	Create,
	Datagrid,
	DeleteWithConfirmButton,
	Edit,
	EmailField,
	Filter,
	List,
	Pagination,
	required,
	SelectInput,
	Show,
	EditButton,
	SimpleForm,
	SimpleList,
	SimpleShowLayout,
	TextField,
	TextInput,
	TopToolbar,
} from "react-admin";

const UserFilter = (props) => (
	<Filter {...props}>
		<TextInput label='Search' source='q' alwaysOn />
		<SelectInput
			label='Roles'
			source='role'
			choices={[
				{ id: "Admin", name: "Admin" },
				{ id: "Super Admin", name: "Super Admin" },
			]}
		/>
	</Filter>
);

export const UserList = (props) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	return (
		<List
			{...props}
			filters={<UserFilter />}
			pagination={<Pagination {...props} rowsPerPageOptions={[]} />}
			bulkActionButtons={<BulkDeleteWithConfirmButton />}
		>
			{isSmall ? (
				<SimpleList
					primaryText={(record) => record.name}
					secondaryText={(record) => record.email}
					tertiaryText={(record) => record.role}
					linkType='show'
				/>
			) : (
				<Datagrid rowClick='show'>
					<TextField source='id' />
					<TextField source='role' />
					<TextField source='username' />
					<EmailField source='email' />
					<EditButton base='/users' />
					<DeleteWithConfirmButton base='/users' />
				</Datagrid>
			)}
		</List>
	);
};

const UserTitle = ({ record }) => {
	return <span>User {record ? `"${record.username}"` : ""}</span>;
};

export const UserEdit = (props) => (
	<Edit title={<UserTitle />} {...props}>
		<SimpleForm>
			<TextInput
				disabled
				source='id'
				fullWidth={true}
				validate={required()}
			/>
			<TextInput
				source='username'
				fullWidth={true}
				validate={required()}
			/>
			<TextInput source='email' fullWidth={true} validate={required()} />
			<TextInput
				source='newPassword'
				label='New Password'
				fullWidth={true}
				validate={required()}
			/>
		</SimpleForm>
	</Edit>
);

export const UserCreate = (props) => (
	<Create {...props}>
		<SimpleForm>
			<TextInput
				source='role'
				defaultValue='Admin'
				fullWidth={true}
				validate={required()}
				disabled
			/>
			<TextInput
				source='username'
				fullWidth={true}
				validate={required()}
			/>
			<TextInput source='email' fullWidth={true} validate={required()} />
			<TextInput
				source='password'
				fullWidth={true}
				validate={required()}
			/>
		</SimpleForm>
	</Create>
);

const UserShowActions = ({ basePath, data, resource }) => (
	<TopToolbar>
		<EditButton basePath={basePath} record={data} resource={resource} />
		<DeleteWithConfirmButton
			basePath={basePath}
			record={data}
			resource={resource}
		/>
	</TopToolbar>
);

export const UserShow = (props) => (
	<Show {...props} title={<UserTitle />} actions={<UserShowActions />}>
		<SimpleShowLayout>
			<TextField source='id' />
			<TextField source='role' />
			<TextField source='username' />
			<EmailField source='email' />
		</SimpleShowLayout>
	</Show>
);
