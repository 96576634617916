import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Admin, Chat } from "./containers";

function App() {
	return (
		<>
			<Router>
				<Switch>
					<Route path='/' exact component={Chat}></Route>
					<Route path='/admin/' exact component={Admin}></Route>
				</Switch>
			</Router>
		</>
	);
}

export default App;
