import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
	* {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		user-select: none;

		& ::-webkit-scrollbar {
			width: 5px;
		}
		& ::-webkit-scrollbar-track {
			border-radius: 10px;
		}
		& ::-webkit-scrollbar-thumb {
			background: #BDBDBD;
			border-radius: 10px;
		}

		& .MuiContainer-root {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	body {
		background: #EBEBEB;
	}

	a{
		text-decoration: none;
	}

	p {
		color: #10285d;
		font-size: 16px;
		line-height: 30px;
		margin-bottom: 15px;
		font-weight: normal;
	}
	h1, h2, h3, h4, h5 {
		color: #4255A4;
		margin-top: 0px;
		font-style: normal;
		font-weight: 400;
	}
	ul, ol, li {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	#root {
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;

		& > .MuiContainer-root {
			height: 90%;
		}

		& .RaLayout-root-1 {
			color: rgba(0, 0, 0, 0.87);
			width: 100%;
			display: flex;
			z-index: 1;
			position: fixed;
			min-width: fit-content;
			height: 100%;
			flex-direction: column;
			overflow: scroll;
			top: 0;
			background-color: #fafafa;
		}

		& .layout {
			& .RaLayout-appFrame-2 {
				height: 100vh;
				margin:0;
			}
			& .RaLayout-contentWithSidebar-3 {
				position: absolute;
				width: 100%;
				padding-top: 48px;
			}
		}

		& .MuiTypography-root > span > p {
			    margin-bottom: 0;
			}
	}
`;

export default GlobalStyles;
