import { Avatar } from "@material-ui/core";
import DOMPurify from "dompurify";
import React from "react";
import avatar from "../../images/avatar.jpg";
import { Msg, Reply, Text } from "./elements";

const Messages = ({ chats }) => {
	DOMPurify.addHook("afterSanitizeAttributes", function (node) {
		// set all elements owning target to target=_blank
		if ("target" in node) {
			node.setAttribute("target", "_blank");
			node.setAttribute("rel", "noopener");
		}
	});
	return chats.map((chat, index) => (
		<>
			{chat.msgType ? (
				<Msg key={index}>
					<Avatar />
					<Text>{chat.msg}</Text>
				</Msg>
			) : (
				<Reply key={index}>
					<Avatar src={avatar} alt='jumppy' />
					<Text
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(chat.msg),
						}}
					></Text>
				</Reply>
			)}
		</>
	));
};

export default Messages;
