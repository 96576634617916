import { Container } from "@material-ui/core";
import Filter from "bad-words";
import React, { useEffect, useState } from "react";
import { Chat, ChatBody, ChatFooter, ChatSuggestions, Col } from "./elements";
import Form from "./Form";
import Loading from "./Loading";
import Messages from "./Messages";
import Suggestions from "./Suggestions";

function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms * 1000));
}

const ChatSection = () => {
	const [chats, setChats] = useState([]);
	const [loading, setLoading] = useState(false);
	const [suggestions, setSuggestions] = useState([]);
	const filter = new Filter();

	const sendPrevChat = (chats) => {
		fetch(`${process.env.REACT_APP_API}/savePrevChats`, {
			method: "POST",
			body: JSON.stringify(chats),
			headers: {
				"Content-Type": "application/json;charset=utf-8",
			},
		});
	};

	const getReply = (query, newChats) => {
		fetch(`${process.env.REACT_APP_API}`, {
			method: "POST",
			body: JSON.stringify(query),
			headers: {
				"Content-Type": "application/json;charset=utf-8",
			},
		})
			.then((resp) => resp.json())
			.then((data) => {
				if (
					data.answer.includes("Sorry") ||
					data.answer.includes("Entschuldigung")
				) {
					sendPrevChat(newChats.slice(1, 11));
					data.suggestions = suggestions;
				}
				setSuggestions(data.suggestions);
				let reply = {
					msgType: false,
					msg: data.answer,
					isYesNo: data.isYesNo,
				};
				if ("isYesNo" in data) {
					reply["yes"] = data.suggestions["yes"];
					reply["no"] = data.suggestions["no"];
					reply["ja"] = data.suggestions["ja"];
					reply["Nein"] = data.suggestions["Nein"];
				}
				setLoading(false);
				setChats([reply, ...newChats]);
			});
	};

	const addChat = async (chat) => {
		if (!chat.msg || /^\s*$/.test(chat.msg) || filter.isProfane(chat.msg)) {
			return;
		}
		let newChats = [chat, ...chats];
		setChats(newChats);
		setLoading(true);
		let query = { key: chat.msg };
		await sleep(1);

		if (
			chats.length > 0 &&
			"isYesNo" in chats[0] &&
			chats[0]["isYesNo"] === true
		) {
			setSuggestions([]);
			let reply = {};
			switch (chat.msg.toLowerCase()) {
				case "yes":
					reply = {
						msgType: false,
						msg: chats[0].yes,
					};
					break;
				case "ja":
					reply = {
						msgType: false,
						msg: chats[0].ja,
					};
					break;
				case "no":
					reply = {
						msgType: false,
						msg: chats[0].no,
					};
					break;
				case "nein":
					reply = {
						msgType: false,
						msg: chats[0].Nein,
					};
					break;
				default:
					getReply(query, newChats);
					break;
			}
			setLoading(false);
			newChats = [reply, chat, ...chats];
			setChats(newChats);
		} else {
			setSuggestions([]);
			getReply(query, newChats);
		}
	};

	const greet = async () => {
		const greetings = {
			msg: "Hallo!! Ich bin Jumppy. Wie kann ich dir helfen?",
			msgType: false,
		};
		setLoading(true);
		await sleep(2);
		setLoading(false);
		setChats([greetings]);
	};

	useEffect(() => {
		greet();
	}, []);

	return (
		<>
			<Chat>
				<Container>
					<Col>
						<ChatBody>
							{loading && <Loading />}
							<Messages chats={chats} />
						</ChatBody>
						{suggestions && (
							<ChatSuggestions>
								<Suggestions
									onSubmit={addChat}
									suggestions={suggestions}
								/>
							</ChatSuggestions>
						)}
						<ChatFooter>
							<Form onSubmit={addChat} />
						</ChatFooter>
					</Col>
				</Container>
			</Chat>
		</>
	);
};

export default ChatSection;
