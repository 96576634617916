import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import Inbox from "@material-ui/icons/Inbox";
import {
	required,
	useGetResourceLabel,
	useListContext,
	useNotify,
	useRedirect,
	useRefresh,
	useResourceContext,
	useTranslate,
} from "ra-core";
import RichTextInput from "ra-input-rich-text";
import React, { useState } from "react";
import {
	BooleanInput,
	BulkDeleteWithConfirmButton,
	ChipField,
	Create,
	CreateButton,
	Datagrid,
	DeleteWithConfirmButton,
	Edit,
	Loading,
	EditButton,
	ExportButton,
	List,
	Pagination,
	ReferenceArrayField,
	ReferenceField,
	ReferenceInput,
	RichTextField,
	SaveButton,
	SelectInput,
	Show,
	ShowButton,
	SimpleForm,
	SimpleList,
	SimpleShowLayout,
	SingleFieldList,
	TextField,
	TextInput,
	Toolbar,
	TopToolbar,
	useGetIdentity,
	ReferenceArrayInput,
	AutocompleteArrayInput,
} from "react-admin";
import { ImportButton } from "react-admin-import-csv";

const useStyles = makeStyles(
	(theme) => ({
		message: {
			textAlign: "center",
			opacity: theme.palette.type === "light" ? 0.5 : 0.8,
			margin: "0 1em",
			color:
				theme.palette.type === "light"
					? "inherit"
					: theme.palette.text.primary,
		},
		icon: {
			width: "9em",
			height: "9em",
		},
		toolbar: {
			textAlign: "center",
			marginTop: "2em",
			display: "flex",
			justifyContent: "space-evenly",
		},
	}),
	{ name: "RaEmpty" }
);

const Empty = (props) => {
	const { basePath, hasCreate } = useListContext(props);
	const resource = useResourceContext(props);
	const classes = useStyles(props);
	const translate = useTranslate();

	const getResourceLabel = useGetResourceLabel();
	const resourceName = translate(`resources.${resource}.forcedCaseName`, {
		smart_count: 0,
		_: getResourceLabel(resource, 0),
	});

	const emptyMessage = translate("ra.page.empty", { name: resourceName });
	const inviteMessage = translate("ra.page.invite");
	return (
		<>
			<div className={classes.message}>
				<Inbox className={classes.icon} />
				<Typography variant='h4' paragraph>
					{emptyMessage}
				</Typography>
				{hasCreate && (
					<Typography variant='body1'>{inviteMessage}</Typography>
				)}
			</div>
			{hasCreate && (
				<div className={classes.toolbar}>
					<CreateButton variant='contained' basePath={basePath} />
					<ImportButton
						variant='contained'
						{...props}
						parseConfig={{ dynamicTyping: true }}
					/>
				</div>
			)}
		</>
	);
};

const QuestionListActions = (props) => {
	const {
		className,
		basePath,
		total,
		resource,
		currentSort,
		filterValues,
		exporter,
	} = props;

	return (
		<TopToolbar className={className}>
			<CreateButton basePath={basePath} />
			<ExportButton
				disabled={total === 0}
				resource={resource}
				sort={currentSort}
				filter={filterValues}
				exporter={exporter}
			/>
			<ImportButton {...props} parseConfig={{ dynamicTyping: true }} />
		</TopToolbar>
	);
};

const useListStyles = makeStyles({
	wrap: {
		maxWidth: "10em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
});

export const QuestionList = ({ permissions, ...props }) => {
	const classes = useListStyles();
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
	const { identity, loading: identityLoading } = useGetIdentity();
	const userId = identityLoading ? 0 : identity.id;
	const role = identityLoading ? 0 : identity.role;

	if (identityLoading) return <Loading />;

	return (
		<List
			{...props}
			pagination={<Pagination rowsPerPageOptions={[]} />}
			filter={{ userId: userId, role: role }}
			perPage={10}
			empty={<Empty />}
			actions={<QuestionListActions {...props} />}
			bulkActionButtons={
				role === "Super Admin" ? <BulkDeleteWithConfirmButton /> : false
			}
		>
			{isXSmall ? (
				<SimpleList
					primaryText={(record) =>
						record.question.length > 20
							? `${record.question.slice(0, 20)}...`
							: record.question
					}
					secondaryText={(record) =>
						record.answer.length > 20
							? `${record.answer.slice(0, 20)}...`
							: record.answer
					}
					tertiaryText={(record) => {
						permissions === "Super Admin" && (
							<ReferenceField
								basePath='questions'
								record={record}
								label='User'
								source='userId'
								reference='users'
								linkType='edit'
							>
								<TextField source='username' />
							</ReferenceField>
						);
					}}
				/>
			) : (
				<Datagrid>
					<TextField source='id' />
					{permissions === "Super Admin" && (
						<ReferenceField
							label='User'
							source='userId'
							reference='users'
							link='show'
						>
							<TextField source='username' />
						</ReferenceField>
					)}
					<TextField source='question' cellClassName={classes.wrap} />
					<RichTextField
						source='answer'
						cellClassName={classes.wrap}
					/>
					<EditButton
						base='/questions'
						label={isSmall ? "" : "Edit"}
					/>
					<ShowButton
						base='/questions'
						label={isSmall ? "" : "Show"}
					/>
				</Datagrid>
			)}
		</List>
	);
};

const QuestionTitle = ({ record }) => {
	return <span>Question #{record ? `${record.id}` : ""}</span>;
};

const useToolbarStyles = makeStyles({
	toolbar: {
		display: "flex",
		justifyContent: "space-between",
	},
});

const EditToolbar = (props) => {
	const classes = useToolbarStyles();
	return (
		<Toolbar {...props} classes={classes}>
			<SaveButton />
			<DeleteWithConfirmButton />
		</Toolbar>
	);
};

const Radio = ({ record }) => {
	const [check, setCheck] = useState(record.isYesNo);

	const handleChange = () => {
		setCheck(!check);
	};
	return (
		<>
			<BooleanInput
				label='Is it a Yes / No Question'
				source='isYesNo'
				defaultValue={record.isYesNo}
				onChange={handleChange}
				disabled={record.isYesNo}
			/>
			{check ? (
				<>
					<TextInput
						multiline={true}
						source='yes'
						fullWidth={true}
						validate={required()}
					/>
					<TextInput
						multiline={true}
						source='no'
						fullWidth={true}
						validate={required()}
					/>
				</>
			) : (
				<ReferenceArrayInput
					label='Related Questions'
					source='suggestedQues'
					reference='questions'
					fullWidth={true}
					pagination={<Pagination />}
				>
					<AutocompleteArrayInput optionText='question' />
				</ReferenceArrayInput>
			)}
		</>
	);
};

export const QuestionEdit = (props) => {
	return (
		<Edit title={<QuestionTitle />} {...props}>
			<SimpleForm toolbar={<EditToolbar />}>
				<ReferenceInput
					label='User'
					source='userId'
					reference='users'
					link='show'
					fullWidth={true}
					validate={required()}
				>
					<SelectInput optionText='username' />
				</ReferenceInput>
				<TextInput
					multiline={true}
					source='question'
					fullWidth={true}
					validate={required()}
				/>
				<RichTextInput
					multiline={true}
					source='answer'
					fullWidth={true}
					validate={required()}
				/>
				<Radio />
			</SimpleForm>
		</Edit>
	);
};

export const QuestionCreate = ({ permissions, ...props }) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const redirect = useRedirect();
	const [check, setCheck] = useState(false);
	const { identity, loading: identityLoading } = useGetIdentity();
	const userId = identityLoading ? 1 : identity.id;
	
	const onSuccess = () => {
		notify(`Question Added`);
		redirect("/questions");
		refresh();
	};

	const handleCheckBox = () => {
		setCheck(!check);
	};

	if (identityLoading) return <Loading />;

	return (
		<Create onSuccess={onSuccess} {...props}>
			<SimpleForm>
				{permissions === "Super Admin" ? (
					<ReferenceInput
						label='User'
						source='userId'
						reference='users'
						link='show'
						fullWidth={true}
						validate={required()}
					>
						<SelectInput optionText='username' />
					</ReferenceInput>
				) : (
					<TextInput
						label='User'
						source='userId'
						defaultValue={userId}
						fullWidth={true}
						validate={required()}
						disabled
					/>
				)}

				<TextInput
					multiline={true}
					source='question'
					fullWidth={true}
					validate={required()}
				/>
				<RichTextInput
					multiline={true}
					source='answer'
					fullWidth={true}
					validate={required()}
				/>
				<BooleanInput
					label='Is it a Yes / No Question'
					source='isYesNo'
					defaultValue={check}
					onChange={handleCheckBox}
				/>
				{check ? (
					<>
						<TextInput
							multiline={true}
							source='yes'
							fullWidth={true}
							validate={required()}
						/>
						<TextInput
							multiline={true}
							source='no'
							fullWidth={true}
							validate={required()}
						/>
					</>
				) : (
					<ReferenceArrayInput
						label='Related Questionsss'
						source='suggestedQues'
						reference='questions'
						fullWidth={true}
						pagination={<Pagination />}
					>
						<AutocompleteArrayInput optionText='question' />
					</ReferenceArrayInput>
				)}
			</SimpleForm>
		</Create>
	);
};

const useShowRadioStyles = makeStyles({
	label: {
		position: "relative",
		margin: "10px 0",
	},
});

const ShowRadio = ({ record }) => {
	const classes = useShowRadioStyles();
	const [check] = useState(record.isYesNo);
	console.log(record)
	const classNames = `${classes.label} MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense`;

	return (
		<>
			{check ? (
				<>
					<>
						<label className={classNames}>
							<span>Yes Response</span>
						</label>
						<ChipField source='suggestedQues.yes' />
					</>
					<br />
					<>
						<label className={classNames}>
							<span>No Response</span>
						</label>
						<ChipField source='suggestedQues.no' />
					</>
				</>
			) : (
				<>
					<label className={classNames}>
						<span>Suggested Questions</span>
					</label>
					<ReferenceArrayField
						label='Suggested Questions'
						reference='questions'
						source='suggestedQues'
						fullWidth={true}
					>
						<SingleFieldList linkType='show'>
							<ChipField source='question' />
						</SingleFieldList>
					</ReferenceArrayField>
				</>
			)}
		</>
	);
};

export const QuestionShow = ({ permissions, ...props }) => (
	<Show {...props}>
		<SimpleShowLayout>
			<TextField source='id' />
			{permissions === "Super Admin" && (
				<ReferenceField
					label='User'
					source='userId'
					reference='users'
					link='show'
					fullWidth={true}
				>
					<TextField source='username' fullWidth={true} />
				</ReferenceField>
			)}
			<TextField multiline='true' source='question' fullWidth={true} />
			<RichTextField source='answer' multiline='true' fullWidth={true} />
			<ShowRadio />
		</SimpleShowLayout>
	</Show>
);
