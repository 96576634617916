import { Button, Container } from "@material-ui/core";
import React from "react";
import logo from "../../images/logo.png";
import barGraph from "../../images/barGraph.png";
import coach from "../../images/coach.png";
import lineGraph from "../../images/lineGraph.png";
import pieChart from "../../images/pieChart.png";
import presentation from "../../images/presentation.png";
import {
	About,
	Col,
	CompanyInfoSection,
	CompanyLinksSection,
	CompanyLogoSection,
	Fig,
	FigImg,
	Figs,
	Img,
	ImgWrapper,
	Info,
	TextWrapper,
	Title,
} from "./elements";

const InfoSection = () => {
	return (
		<>
			<Info>
				<Container>
					<Col>
						<CompanyLogoSection>
							<ImgWrapper>
								<Img src={logo} alt='logo' />
							</ImgWrapper>
							<TextWrapper>
								<Title>Hallo!! Ich bin Jumppy</Title>
							</TextWrapper>
						</CompanyLogoSection>
						<CompanyInfoSection>
							<Figs>
								<FigImg src={barGraph} />
								<FigImg src={pieChart} />
								<FigImg src={presentation} />
								<FigImg src={lineGraph} />
							</Figs>
							<Fig>
								<FigImg src={coach} />
							</Fig>
						</CompanyInfoSection>
						<CompanyLinksSection>
							<About>
								Ein Buch oder ein anderes schriftliches oder
								gedrucktes Werk, das eher inhaltlich als
								physisch betrachtet wird.
							</About>
							<Button variant='contained' color='primary'>
								Submit
							</Button>
						</CompanyLinksSection>
					</Col>
				</Container>
			</Info>
		</>
	);
};

export default InfoSection;
