import decodeJwt from "jwt-decode";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	// called when the user attempts to log in
	login: ({ username, password }) => {
		const request = new Request(`${process.env.REACT_APP_API}/login`, {
			method: "POST",
			body: JSON.stringify({ username, password }),
			headers: new Headers({ "Content-Type": "application/json" }),
		});
		return fetch(request)
			.then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then(({ token }) => {
				localStorage.setItem("token", token);
			})
			.catch(() => {
				throw new Error("Network error");
			});
		// localStorage.setItem("username", username);
		// // accept all username/password combinations
		// return Promise.resolve();
	},
	// called when the user clicks on the logout button
	logout: () => {
		localStorage.removeItem("token");
		return Promise.resolve();
	},
	// called when the API returns an error
	checkError: ({ status }) => {
		if (status === 401 || status === 403) {
			localStorage.removeItem("token");
			return Promise.reject();
		}
		return Promise.resolve();
	},
	// called when the user navigates to a new location, to check for authentication
	checkAuth: () => {
		return localStorage.getItem("token")
			? Promise.resolve()
			: Promise.reject();
	},
	// called when the user navigates to a new location, to check for permissions / roles
	getPermissions: () => {
		const role = decodeJwt(localStorage.getItem("token")).sub.role;
		return role ? Promise.resolve(role) : Promise.reject();
	},
	getIdentity: () =>
		Promise.resolve({
			fullName: decodeJwt(localStorage.getItem("token")).sub.username,
			id: decodeJwt(localStorage.getItem("token")).sub.id,
			avatar: "temp",
			role: decodeJwt(localStorage.getItem("token")).sub.role,
		}),
};
