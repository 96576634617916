/* eslint-disable import/no-anonymous-default-export */
import decodeJwt from "jwt-decode";
import { stringify } from "query-string";
import { fetchUtils } from "react-admin";

const apiUrl = process.env.REACT_APP_API;
const httpClient = fetchUtils.fetchJson;

const sleep = async (ms) => {
	return new Promise((resolve) => setTimeout(resolve, ms * 1000));
};

export default {
	getList: (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const query = {
			...fetchUtils.flattenObject(params.filter),
			_sort: field,
			_order: order,
			_start: (page - 1) * perPage,
			_end: page * perPage,
		};
		const url = `${apiUrl}/${resource}?${stringify(query)}`;

		return httpClient(url).then(({ json }) => {
			if (resource === "questions" && params.filter.role === "Admin") {
				json = json.filter(
					(data) => data.userId === params.filter.userId
				);
			}

			let jsonPerPage = json.slice(query._start, query._end);

			return {
				data: jsonPerPage,
				total: parseInt(json.length, 10),
			};
		});
	},

	getOne: (resource, params) =>
		httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
			data: json,
		})),

	getMany: (resource, params) => {
		const query = {
			filter: JSON.stringify({ id: params.ids }),
		};
		const url = `${apiUrl}/${resource}?${stringify(query)}`;
		return httpClient(url).then(({ json }) => ({ data: json }));
	},

	getManyReference: (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const query = {
			sort: JSON.stringify([field, order]),
			range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
			filter: JSON.stringify({
				...params.filter,
				[params.target]: params.id,
			}),
		};
		const url = `${apiUrl}/${resource}?${stringify(query)}`;

		return httpClient(url).then(({ headers, json }) => ({
			data: json,
			total: parseInt(json.length, 10),
		}));
	},

	update: (resource, params) =>
		httpClient(`${apiUrl}/${resource}/${params.id}`, {
			method: "PUT",
			body: JSON.stringify(params.data),
		}).then(({ json }) => ({ data: json })),

	// json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
	updateMany: (resource, params) =>
		Promise.all(
			params.ids.map((id) =>
				httpClient(`${apiUrl}/${resource}/${id}`, {
					method: "PUT",
					body: JSON.stringify(params.data),
				})
			)
		).then((responses) => ({
			data: responses.map(({ json }) => json._id),
		})),

	create: (resource, params) => {
		return httpClient(`${apiUrl}/${resource}`, {
			method: "POST",
			body: JSON.stringify(params.data),
		}).then(({ json }) => ({
			data: { ...params.data, id: json._id },
		}));
	},

	createMany: async (resource, params) => {
		let count = 0;
		fetch(`${apiUrl}/${resource}/count`)
			.then((resp) => resp.json())
			.then((data) => (count = data));
		await sleep(2);

		const userId = decodeJwt(localStorage.getItem("token")).sub.id;
		console.log(count)
		return Promise.all(
			params.data.map(async (data, index) => {
				if (data.hasOwnProperty("suggestedQues")) {
					data["suggestedQues"] = JSON.parse(data["suggestedQues"]);
				}
				if (!data.hasOwnProperty("userId")) {
					data["userId"] = userId;
				}
				data["_id"] = count + index + 1;
				data["import"] = true;
				return httpClient(`${apiUrl}/${resource}`, {
					method: "POST",
					body: JSON.stringify(data),
				});
			})
		).then((responses) => {
			return {
				data: responses,
			};
		});
	},

	delete: (resource, params) =>
		httpClient(`${apiUrl}/${resource}/${params.id}`, {
			method: "DELETE",
		}).then(({ json }) => ({ data: json })),

	// json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
	deleteMany: (resource, params) => {
		return Promise.all(
			params.ids.map((id) =>
				httpClient(`${apiUrl}/${resource}/${id}`, {
					method: "DELETE",
				})
			)
		).then((responses) => ({
			data: responses.map(({ json }) => json._id),
		}));
	},
};
