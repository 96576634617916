import { Container } from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
import { ChatSection, InfoSection, CompanySection } from "../components";

export const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	height: 100%;
	width: 100%;
	border-radius: 15px;
	background: #fff;
`;

const ChatContainer = () => {
	const [mobile, setMobile] = useState(false);
	const handleResize = (e) => {
		const windowSize = window.innerWidth;
		setMobile(windowSize < 930 ? true : false);
	};
	window.addEventListener("resize", handleResize);
	window.addEventListener("load", handleResize);
	return (
		<>
			<Container>
				<Row>
					{mobile ? (
						<>
							<ChatSection />
						</>
					) : (
						<>
							<CompanySection />
							<ChatSection />
							<InfoSection />
						</>
					)}
				</Row>
			</Container>
		</>
	);
};

export default ChatContainer;
