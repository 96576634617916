import styled from "styled-components";

export const Chat = styled.section`
	max-width: 100%;
	width: -webkit-fill-available;
	height: 100%;

	& .MuiContainer-root {
		padding: 0;
	}
`;

export const Col = styled.div`
	height: 95%;
	width: 100%;
	margin: 0 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	background: #f6f5f5;
	border-radius: 15px;
`;

export const ChatBody = styled.div`
	flex: 1;
	margin: 10px 0;
	padding: 10px 0;
	border-radius: 15px;
	display: flex;
	flex-direction: column-reverse;
	overflow-y: scroll;
	padding: 10px;
`;

export const ChatFooter = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 70px;
	background: #e6e7e9;
	border-radius: 10px;
	& .MuiIconButton-root {
		padding: 10px;
		font-size: 24px;
	}
	& form {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		width: 100%;
		& input {
			border-radius: 30px;
			padding: 10px;
			border: none;
			outline-width: 0;
			font-size: 1.15rem;
			flex-basis: 80%;
			width: 80%;
		}
		& button {
			display: none;
		}
		& .MuiButtonBase-root {
			display: inline-flex !important;
		}
	}
`;

export const ChatSuggestions = styled(ChatFooter)`
	margin-bottom: 1rem;
	height: unset;
	& form {
		flex-wrap: wrap;
		justify-content: space-around;

		& button {
			margin: 5px;
		}
	}
	${ChatFooter}
`;

export const Msg = styled.div`
	position: relative;
	padding: 10px;
	max-width: 95%;
	margin-bottom: 0;
	margin-left: auto;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;

	& p {
		background-color: #c4e1e4;
	}
`;

export const Reply = styled.div`
	position: relative;
	padding: 10px;
	max-width: 95%;
	margin-bottom: 0;
	display: flex;
	flex-direction: row;
	align-items: center;

	& p {
		background-color: #fff;
	}
`;

export const Text = styled.p`
	position: relative;
	margin: 0 10px;
	font-size: 1.15rem;
	align-self: center;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	padding: 10px;
	max-width: 100%;
	min-width: 30%;
	overflow-wrap: anywhere;
	
	& p {
		margin-bottom: 0;
		font-size: 1.15rem;
	}
`;

export const SendButton = styled.div`
	padding: 0 10px;
	height: unset;
`;

export const Img = styled.img`
	height: 50px;
	margin-left: 20px;
`;
