import React from "react";
import loadingGif from '../../images/loadingChat.gif';
import { Reply, Img } from "./elements";

const Loading = () => {
	return (
		<>
			<Reply><Img src={loadingGif} alt='loading' /></Reply>
		</>
	);
};

export default Loading;
