import { makeStyles, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Inbox from "@material-ui/icons/Inbox";
import DOMPurify from "dompurify";
import {
	required,
	useGetResourceLabel,
	useResourceContext,
	useTranslate
} from "ra-core";
import RichTextInput from "ra-input-rich-text";
import React, { useState } from "react";
import {
	BooleanInput,
	BulkDeleteWithConfirmButton,
	Datagrid,
	DeleteWithConfirmButton,
	Edit,
	List,
	Pagination,
	ReferenceArrayInput,
	SaveButton,
	SelectArrayInput,
	Show,
	SimpleForm,
	SimpleShowLayout,
	TextField,
	TextInput,
	Toolbar
} from "react-admin";

const useStyles = makeStyles(
	(theme) => ({
		message: {
			textAlign: "center",
			opacity: theme.palette.type === "light" ? 0.5 : 0.8,
			margin: "0 1em",
			color:
				theme.palette.type === "light"
					? "inherit"
					: theme.palette.text.primary,
		},
		icon: {
			width: "9em",
			height: "9em",
		},
		toolbar: {
			textAlign: "center",
			marginTop: "2em",
			display: "flex",
			justifyContent: "space-evenly",
		},
	}),
	{ name: "RaEmpty" }
);

const Empty = (props) => {
	const resource = useResourceContext(props);
	const classes = useStyles(props);
	const translate = useTranslate();

	const getResourceLabel = useGetResourceLabel();
	const resourceName = translate(`resources.${resource}.forcedCaseName`, {
		smart_count: 0,
		_: getResourceLabel(resource, 0),
	});

	const emptyMessage = translate("ra.page.empty", { name: resourceName });
	return (
		<>
			<div className={classes.message}>
				<Inbox className={classes.icon} />
				<Typography variant='h4' paragraph>
					{emptyMessage}
				</Typography>
			</div>
		</>
	);
};

const useListStyles = makeStyles({
	wrap: {
		maxWidth: "10em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
});

export const UnansweredList = (props) => {
	const classes = useListStyles();
	return (
		<List
			{...props}
			perPage={10}
			empty={<Empty />}
			pagination={<Pagination {...props} rowsPerPageOptions={[]} />}
			bulkActionButtons={<BulkDeleteWithConfirmButton />}
		>
			<Datagrid rowClick='show'>
				<TextField source='id' />
				<TextField source='question' cellClassName={classes.wrap} />
				<DeleteWithConfirmButton base='/unanswered' />
			</Datagrid>
		</List>
	);
};

const useShowStyles = makeStyles({
	label: {
		position: "relative",
		margin: "10px 0",
	},
});

export const UnansweredShow = (props) => {
	const classes = useShowStyles();
	const classNames = `${classes.label} MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense`;
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<TextField source='id' />
				<TextField
					source='question'
					multiline='true'
					fullWidth={true}
				/>
				<>
					<label className={classNames}>
						<span>Previous Chats</span>
					</label>
				</>
				<CustomizedTables />
			</SimpleShowLayout>
		</Show>
	);
};

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
		width: "50%",
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useTableStyles = makeStyles({
	table: {
		maxWidth: "100%",
	},
});

const CustomizedTables = ({ record }) => {
	const classes = useTableStyles();
	DOMPurify.addHook("afterSanitizeAttributes", function (node) {
		// set all elements owning target to target=_blank
		if ("target" in node) {
			node.setAttribute("target", "_blank");
			node.setAttribute("rel", "noopener");
		}
	});
	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label='customized table'>
				<TableHead>
					<TableRow>
						<StyledTableCell align='center'>
							Jumppy Reply
						</StyledTableCell>
						<StyledTableCell align='center'>
							User Query
						</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{record.prevChats?.reverse().map((row, index) => {
						if (row.msgType)
							return (
								<StyledTableRow key={index}>
									<StyledTableCell></StyledTableCell>
									<StyledTableCell align='center'>
										{row.msg}
									</StyledTableCell>
								</StyledTableRow>
							);
						return (
							<StyledTableRow key={index}>
								<StyledTableCell
									align='center'
									dangerouslySetInnerHTML={{
										__html: DOMPurify.sanitize(row.msg),
									}}
								></StyledTableCell>
								<StyledTableCell></StyledTableCell>
							</StyledTableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const QuestionTitle = ({ record }) => {
	return <span>Unanswered {record ? `#${record.id}` : ""}</span>;
};

const useToolbarStyles = makeStyles({
	toolbar: {
		display: "flex",
		justifyContent: "space-between",
	},
});

const EditToolbar = (props) => {
	const classes = useToolbarStyles();
	return (
		<Toolbar {...props} classes={classes}>
			<SaveButton />
			<DeleteWithConfirmButton />
		</Toolbar>
	);
};

export const UnansweredEdit = ({ permissions, ...props }) => {
	const [check, setCheck] = useState(false);
	const handleCheckBox = () => {
		setCheck(!check);
	};

	return (
		<Edit title={<QuestionTitle />} {...props}>
			<SimpleForm toolbar={<EditToolbar />}>
				<TextInput
					multiline={true}
					source='question'
					fullWidth={true}
					validate={required()}
				/>
				<RichTextInput
					multiline={true}
					source='answer'
					fullWidth={true}
					validate={required()}
				/>
				<BooleanInput
					label='Is it a Yes / No Question'
					source='isYesNo'
					defaultValue={check}
					onChange={handleCheckBox}
				/>
				{check ? (
					<>
						<TextInput
							multiline={true}
							source='yes'
							fullWidth={true}
							validate={required()}
						/>
						<TextInput
							multiline={true}
							source='no'
							fullWidth={true}
							validate={required()}
						/>
					</>
				) : (
					<ReferenceArrayInput
						label='Related Questions'
						source='suggestedQues'
						reference='questions'
						fullWidth={true}
					>
						<SelectArrayInput optionText='question' />
					</ReferenceArrayInput>
				)}
			</SimpleForm>
		</Edit>
	);
};
