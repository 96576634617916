import styled from "styled-components";

export const Info = styled.section`
	max-width: 30%;
	height: 100%;
`;

export const Col = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	height: 100%;
`;

export const CompanyLogoSection = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: #e4fbff;
	border-radius: 15px;
`;

export const CompanyInfoSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	background: #f3f3f3;
	border-radius: 15px;
	padding: 5px;
`;

export const CompanyLinksSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	background: #e4fbff;
	border-radius: 15px;
	padding: 5px;
`;

export const ImgWrapper = styled.div`
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 15px 0;
`;

export const Img = styled.img`
	height: 100%;
	border-radius: 50%;
`;

export const TextWrapper = styled.div``;

export const Title = styled.h3`
	font-size: 1.5rem;
	font-weight: 400;
	color: #1f1f1f;
	margin-bottom: 10px;
	text-align: center;
`;

export const Designation = styled.p`
	font-size: 0.95rem;
	line-height: 21px;
	margin-bottom: 0;
	text-align: center;
`;

export const SectionTitle = styled.h4`
	font-size: 1.5rem;
	font-weight: 400;
	color: #1f1f1f;
	margin-bottom: 10px;
`;

export const About = styled.p`
	font-size: 16px;
	line-height: 1;
	margin-bottom: 0;
	background: #e4fbff;
	border-radius: 15px;
	padding: 10px;
	/* margin-bottom: 2rem; */
`;

export const Figs = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 10px;
	justify-items: center;

	& :nth-child(1) {
		background: #c4fbf5;
		grid-area: 1 / 1 / 2 / 2;
	}
	& :nth-child(2) {
		background: #ffdcb0;
		grid-area: 1 / 2 / 2 / 3;
	}
	& :nth-child(3) {
		background: #feddcb;
		grid-area: 2 / 2 / 3 / 3;
	}
	& :nth-child(4) {
		background: #d9d5fb;
		grid-area: 2 / 1 / 3 / 2;
	}
`;

export const FigImg = styled.img`
	height: 60px;
	padding: 10px 25px;
	border-radius: 20px;
`;

export const Fig = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	& img {
		height: 180px;
		padding: 20px;
	}
`;
