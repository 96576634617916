import ErrorIcon from "@material-ui/icons/Error";
import QuestionIcon from "@material-ui/icons/Help";
import UserIcon from "@material-ui/icons/People";
import { Admin, Resource } from "react-admin";
import styled from "styled-components";
import authProvider from "../components/AdminSection/authProvider";
import Dashboard from "../components/AdminSection/dashboard";
import {
	QuestionCreate,
	QuestionEdit,
	QuestionList,
	QuestionShow
} from "../components/AdminSection/questions";
import {
	UnansweredEdit,
	UnansweredList,
	UnansweredShow
} from "../components/AdminSection/unanswered";
import {
	UserCreate,
	UserList,UserEdit,
	UserShow
} from "../components/AdminSection/users";
import LoginPage from '../components/LoginPage/index.tsx';
import dataProvider from "../dataProvider";

const AdminSection = styled.section`
	width: 100vw;
`;

const AdminContainer = () => {
	return (
		<AdminSection>
			<Admin
				title='My Custom Admin'
				dataProvider={dataProvider}
				authProvider={authProvider}
				loginPage={LoginPage}
				dashboard={Dashboard}
			>
				{(permissions) => [
					<Resource
						name='questions'
						icon={QuestionIcon}
						list={QuestionList}
						edit={QuestionEdit}
						show={QuestionShow}
						create={QuestionCreate}
					/>,
					permissions === "Super Admin" && (
						<Resource
							name='users'
							icon={UserIcon}
							list={UserList}
							edit={UserEdit}
							show={UserShow}
							create={UserCreate}
						/>
					),
					permissions === "Super Admin" && (
						<Resource
							name='unanswered'
							options={{ label: "Unanswered" }}
							icon={ErrorIcon}
							list={UnansweredList}
							show={UnansweredShow}
							edit={UnansweredEdit}
						/>
					),
				]}
			</Admin>
		</AdminSection>
	);
};

export default AdminContainer;
