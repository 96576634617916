import { Button } from "@material-ui/core";
import React, { useState } from "react";

const Form = ({ onSubmit }) => {
	const [input, setInput] = useState("");

	const handleChange = (e) => {
		setInput(e.target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		onSubmit({
			msgType: true,
			msg: input,
		});
		setInput("");
	};
	return (
		<>
			<form onSubmit={handleSubmit}>
				<input
					type='text'
					placeholder='Type a message'
					value={input}
					onChange={handleChange}
					autoFocus
				/>
				<button type='submit'>Send a message</button>

				<Button
					onClick={handleSubmit}
					variant='contained'
					color='primary'
				>
					Send
				</Button>
			</form>
		</>
	);
};

export default Form;
