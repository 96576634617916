import { Button } from "@material-ui/core";
import React from "react";

const Suggestions = ({ onSubmit, suggestions }) => {
	const handleSubmit = (e) => {
		e.preventDefault();

		onSubmit({
			msgType: true,
			msg: e.target.textContent,
		});
	};
	if (!Array.isArray(suggestions)) {
		suggestions = Object.keys(suggestions).map(
			(key) => `${key[0].toUpperCase()}${key.substring(1)}`
		);
	}
	return (
		<>
			<form onSubmit={handleSubmit}>
				{suggestions.map((suggestion) => (
					<Button
						onClick={handleSubmit}
						variant='contained'
						color='primary'
					>
						{suggestion}
					</Button>
				))}
			</form>
		</>
	);
};

export default Suggestions;
